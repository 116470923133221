.blogItem-wrap {
  display: flex;
  flex-direction: column;
  background:white;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  border-radius: 15px;
}

.blogItem-wrap:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  cursor:pointer;
}

.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 0.5r;
}

.blogItem-wrap h3 {
  margin: 0.5rem 0 0.5rem 0;
  flex: 1;
  text-align:center;
}
.blogItem-wrap p{
  margin-bottom:0;
}

.blogItem-description {
  position: relative;
  max-height: 50px;
  overflow: hidden;
  font-size: 0.8rem;
  padding-right: 0.6rem;
  color: #a9a9a9;
  text-align:justify;
}

.blogItem-description::before {
  position: absolute;
  content: "...";
  bottom: 0;
  right: 0;
}

.blogItem-wrap footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
}

.blogItem-link {
  text-decoration: none;
  color: inherit;
}

.blogItem-author {
  display: flex;
  align-items: center;
}

.blogItem-author img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-author p {
  font-size: 0.6rem;
  color: #a9a9a9;
  font-weight: 600;
}
