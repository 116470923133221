.workList-wrap{
    display:grid;
    grid-template-columns:repeat(2, 1fr);
    grid-gap: 3rem;
}

@media(max-width:768px){
    .workList-wrap{ 
        display:grid;
        grid-template-columns:repeat(2, 1fr);
        grid-gap: 3rem;
    }
}

@media(max-width:600px){
    .workList-wrap{ 
        display:grid;
        grid-template-columns:repeat(1, 1fr);
        grid-gap: 3rem;
    }
}