.home-header{
    text-align:center;
}

.home-header h2{
    color:#0080ff;
    font-size:2rem;
}

.home-header h1{
    font-size:3rem;
    color:blue;
    margin-bottom:1rem;
}

.home-header h1 span{
    color:#b0c4de;
}

.home-header p{
    color:#a9a9a9;
    font-wigth:500;
}