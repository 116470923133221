.ir-arriba {
  background-repeat: no-repeat;
  font-size: 3rem;
  color: #6190e8;
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  opacity: 100%;
  transition: opacity 0.5s;
}

.ir-arriba:hover {
  opacity: 60%;
}


.progress-bar {
  height: .3rem;
  background: #6190e8;
  position: fixed;
  top: 0;
  left: 0;
}

.hidden {
  opacity: 0%;
}