.searchBar-wrap{
    background-color:#f0f0f0;
    width:fit-content;
    margin:1.5rem auto 1.5rem auto;
    padding: .5rem;
    border-radius:5px;
}

.searchBar-wrap form{
    display:flex;
    align-item:center;
}

.searchBar-wrap input{
    background-color:#f0f0f0;
    outline:none;
    border:none;
}

.searchBar-wrap span{
    padding-right: .5rem;
    cursor:pointer;
}

.searchBar-wrap button{
    outline:none;
    border:none;
    padding:.3rem 1rem;
    border-radius:5px;
    background-color:#0f52ba;
    color:#fff;
}